import React from 'react'
import { useIntl } from 'gatsby-plugin-intl'
import { 
  Box, 
  Popper, 
  ClickAwayListener, 
  makeStyles, 
  Grid} from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import { CustomButton } from '../../CustomButton'
import MailForm from '../propertyForm/MailForm'

const useStyles = makeStyles((theme) => ({
  paper: {
    border: '1px solid',
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,

  },
  searchBtnAlt: {
    fontFamily: `'Gotham Bold', serif`,
    fontSize: theme.typography.pxToRem(9),
    lineHeight: 2,
    width: '100%',
  },
  popper: {
    position: 'relative',
    zIndex: 1300,
    margin: '6px 0',
    width: '400px',
    [theme.breakpoints.between('xs', 'sm')]: {
      marginLeft: theme.spacing(0.1),
      width: '100%',
    },
  },
  btn: {
    fontFamily: `'Gotham Book', serif`,
    fontSize: theme.typography.pxToRem(13.8),
    marginBottom: '5rem',
    [theme.breakpoints.between('md', 'md')]: {
      fontSize: theme.typography.pxToRem(10),
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: theme.typography.pxToRem(18),
    },
  },
}))

const ButtonFormPopper = ({ 
  displayedText = '',
}) => {
  const [openPopper, setOpenPopper] = React.useState(false);
  const [formValidated, setFormValidate] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const classes = useStyles();
  const theme = useTheme();
  const intl = useIntl();

  const handleClickPopper = (event?: any) => {
    setAnchorEl(event.currentTarget);
    setOpenPopper((previousOpen) => !previousOpen);
  };

  const clickAwayHandler = (validated: any) => {
    setAnchorEl(null);
    setOpenPopper(false);
    setFormValidate(validated == true)
  };

  return (
    <Grid container justifyContent="center" className={classes.btn}>
      <CustomButton
        displayIcon={true}
        onClick={handleClickPopper}
        width={'auto'}
        textTransform={'inherit'}
        height={'40px'}
      >
        <span style={{ fontSize: '0.8rem', padding: '0 0px', fontWeight: 400 }}>
          {!formValidated ? displayedText : intl.formatMessage({ id: 'send_confirm' })}
        </span>
      </CustomButton>
      <Popper
        open={openPopper && !formValidated}
        anchorEl={anchorEl}
        className={classes.popper}
        modifiers={{
          flip: {
            enabled: true,
          },
          preventOverflow: {
            enabled: true,
            boundariesElement: 'scrollParent',
          },
          arrow: {
            enabled: false,
          },
        }}
      >
        <ClickAwayListener onClickAway={clickAwayHandler}>
          <Box className={classes.paper}>
            <Box style={{ fontSize: '1rem', fontWeight: 'bold', padding: theme.spacing(1), textAlign: 'center' }}>
              {intl.formatMessage({ id: 'agencyPage.partner.btnText' })}
            </Box>
            <Box style={{padding: theme.spacing(0.5)}}>
              <MailForm initialValues={{
                  emailTo: 'contact@kretzrealestate.com' as any,
                  email: null,
                  name: null,
                  message: null,
                  form: 'FCO',
                  consent: false,
                }}
                onSuccess={clickAwayHandler as any}
              />
            </Box>
          </Box>
        </ClickAwayListener>
      </Popper>
    </Grid>
  );
};

export default ButtonFormPopper