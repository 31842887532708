import React, { useEffect, useRef, useState } from 'react'

import { useIntl } from 'gatsby-plugin-intl'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { Avatar } from '../Avatar'
import AgentContactViaEmail from './AgenctContactViaEmail'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import useWindowSize from '../../utils/useWindowSize'
import { graphql, useStaticQuery } from 'gatsby'

import { capitalize } from '../../utils/helper'

const useStyles = makeStyles((theme) => ({
  teamTitle: {
    fontFamily: `'Cormorant Garamond', serif`,
    fontSize: theme.typography.pxToRem(130),
    textAlign: 'center',
    textTransform: 'uppercase',
    [theme.breakpoints.between('md', 'md')]: {
      fontSize: theme.typography.pxToRem(80),
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: theme.typography.pxToRem(40),
    },
  },
  root: {
    width: '100%',
    margin: '2em 0',
    [theme.breakpoints.between('md', 'md')]: {
      margin: '1.5em 0',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      margin: '.8em 0',
    },
  },
  accordionSummary: {
    background: 'transparent',
    borderTop: `${theme.typography.pxToRem(1)} solid #b7b0b0`,
    boxShadow: 'none',
    borderRadius: 'unset',
  },
  heading: {
    fontFamily: `'Gotham Bold', serif`,
    fontSize: theme.typography.pxToRem(25),
    textAlign: 'center',
    textTransform: 'uppercase',
    width: '100%',
    lineHeight: 1.2,
    letterSpacing: '0.02em',
    [theme.breakpoints.between('md', 'md')]: {
      fontSize: theme.typography.pxToRem(18),
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: theme.typography.pxToRem(18),
    },
  },
  accordionDetails: {
    borderTop: `${theme.typography.pxToRem(1)} solid #b7b0b0`,
  },
  teamContainer: {
    width: '100%',
  },
}))

type ProfileType = {
  src: string
  name: string
  phone: string
  isPhoneDisplayed: boolean
  email: string
  designation: string
  about?: string
  photo: {
    portraitSquare: string
  }
}

interface DetailsProps {
  profiles: ProfileType[]
}

const Details: React.FC<DetailsProps> = (props) => {
  const { profiles } = props
  const intl = useIntl()

  const [containerWidth, setContainerWidth] = useState<string>('0px')
  const [avatarWidth, setAvatarWidth] = useState<number>(0)
  const [selectedAgent, setSelectedAgent] = useState<number | undefined>(undefined)
  const classes = useStyles()
  const divRef = useRef<HTMLInputElement>(null)
  const avatarRef = useRef<HTMLInputElement>(null)
  const theme = useTheme()
  const isSm = useMediaQuery(theme.breakpoints.only('sm'))
  const isMd = useMediaQuery(theme.breakpoints.only('md'))
  const isXs = useMediaQuery(theme.breakpoints.only('xs'))
  const isLg = useMediaQuery(theme.breakpoints.only('lg'))
  const isXl = useMediaQuery(theme.breakpoints.only('xl'))
  const onResize = React.useCallback(() => {
    setSelectedAgent(undefined)
    divRef?.current?.clientWidth && setContainerWidth(`${divRef?.current?.clientWidth}px`)
    avatarRef?.current?.clientWidth && setAvatarWidth(avatarRef?.current?.clientWidth)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSm, isXs, isMd])

  useWindowSize(onResize)

  const getMargin = () => {
    if (!selectedAgent) {
      return 0
    } else if (isXs || isSm) {
      return 0
    } else if (isMd) {
      return (selectedAgent % 2) * avatarWidth
    } else if (isLg || isXl) {
      return (selectedAgent % 3) * avatarWidth
    }
  }

  const selectAvatar = (index: number) => {
    onResize()
    setSelectedAgent(index)
  }

  React.useEffect(() => {
    onResize()
  }, [onResize])

  return (
    <div ref={divRef} style={{ width: '100%', paddingTop: '1rem', paddingBottom: '1rem' }}>
      <Grid container>
        {profiles.map((profile, index) => (
          <Grid item xs={12} md={6} lg={4}>
            <div ref={avatarRef}>
              <Avatar
                onClick={() => selectAvatar(index)}
                active={selectedAgent === index}
                hideNumber={!profile.isPhoneDisplayed}
                agent={profile}
              />
            </div>
            {selectedAgent === index && (
              <AgentContactViaEmail
                marginLeft={getMargin()}
                width={containerWidth}
                details={profile[`description${capitalize(intl.locale)}`]}
                emailTo={profile.email}
                onClose={() => setSelectedAgent(undefined)}
                isPhoneDisplayed={profile.isPhoneDisplayed}
                phone={profile.isPhoneDisplayed ? profile.phone : null}
              />
            )}
          </Grid>
        ))}
      </Grid>
    </div>
  )
}

const Team = () => {
  const intl = useIntl()
  const classes = useStyles()
  const [expanded, setExpanded] = React.useState<string | false>(false)

  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false)
  }

  const {
    backoffice: {
      publicUserList: { edges },
    },
  } = useStaticQuery(graphql`
    query FamilyQuery {
      backoffice {
        publicUserList {
          edges {
            node {
              weight
              destination
              id
              name
              email
              isPhoneDisplayed
              phone
              titreAr
              titreEn
              titreFr
              titreRu
              titreZh
              titreEs
              titrePt
              descriptionAr
              descriptionEn
              descriptionEs
              descriptionFr
              descriptionPt
              descriptionRu
              descriptionZh
              photo {
                portraitSquare
              }
            }
          }
        }
      }
    }
  `)

  const teamList = edges.reduce(
    (acc: any, { node, node: { destination } }) => ({
      ...acc,
      [destination]: [...(acc[destination] || []), node],
    }),
    {},
  )

  const weight = {
    DDR_PARIS: 1,
    DDR_USA: 2,
    DDR_ESPAGNE: 3,
    DDR_PORTUGAL: 4,
    DDR_BALI: 5,
    DDR_GRECE: 6,
    DDR_LONDON: 7,
    DDR_NORMANDIE: 8,
    DDR_OCCITANIE: 9,
    DDR_ATLANTIQUE: 10,
    DDR_MEDITERRANEE: 11,
    DDR_MONTAGNE: 12,
    DDR_ILES: 13,
    DDR_BRETAGNES: 14,
    DDR_BORDEAUX: 15,
    DDR_BASQUE: 16,
    DDR_CORSE: 17,
    DDR_LYON: 18,
    DDR_CENTREVDL: 19,
    DDR_INTERNATIONAL: 20,
    DDR_MONACO: 21,
    DDR_GRANDEST: 22,
    DDR_HAUTDEFRANCE: 23,
    A_: 24,
  }

  useEffect(() => {
    if (expanded && document) {
      const targetEle = document.getElementById('team-anchor')
      const YOffsetRows = (Number(weight[expanded]) + 1) * 48
      const YOffsetHeader = -70

      if (targetEle) {
        const pos = targetEle?.style.position
        const top = targetEle?.style.top
        targetEle.style.position = 'relative'
        targetEle.style.top = YOffsetRows + YOffsetHeader + 'px'
        targetEle?.scrollIntoView({ behavior: 'smooth', block: 'start' })
        targetEle.style.top = top?.toString()
        targetEle.style.position = pos?.toString()
      }
    }
  }, [expanded])

  return (
    <Grid container justifyContent="center" className={classes.teamContainer}>
      <div id={'team-anchor'} style={{ position: 'absolute', left: 0 }}></div>
      <Grid item xs={1} md={2} lg={2} />
      <Grid item xs={10} md={8} lg={8}>
        <Grid container justifyContent="center">
          <Box className={classes.teamTitle}>{intl.formatMessage({ id: 'link.team' })}</Box>
          <div className={classes.root}>
            {Object.keys(weight).map(
              (teamKey) =>
                teamList[teamKey] &&
                teamList[teamKey].length > 0 && (
                  <Accordion
                    id={teamKey}
                    key={teamKey}
                    expanded={expanded === teamKey}
                    onChange={handleChange(teamKey)}
                    className={classes.accordionSummary}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      <Typography className={classes.heading}>
                        {intl.formatMessage({ id: teamKey })}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.accordionDetails}>
                      <Details profiles={teamList[teamKey]} />
                    </AccordionDetails>
                  </Accordion>
                ),
            )}
          </div>
        </Grid>
      </Grid>
      <Grid item xs={1} md={2} lg={2} />
    </Grid>
  )
}

export default Team
