import React from 'react'

import { useIntl } from 'gatsby-plugin-intl'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import ButtonFormPopper from './ModalForm/ButtonFormPopper'

const useStyles = makeStyles((theme) => ({
  partner: {
    justifyContent: 'center',
  },
  heading: {
    fontFamily: `'Cormorant Garamond', serif`,
    fontSize: theme.typography.pxToRem(130),
    textAlign: 'center',
    textTransform: 'uppercase',
    lineHeight: '.9',
    [theme.breakpoints.between('md', 'md')]: {
      fontSize: theme.typography.pxToRem(80),
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: theme.typography.pxToRem(40),
    },
  },
  partnerHeading: {
    textAlign: 'left',
    fontSize: theme.typography.pxToRem(18),
    fontWeight: 'bold',
    fontFamily: `'Gotham Book', serif`,
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: theme.typography.pxToRem(15),
      textAlign: 'left',
    },
  },
  partnerDescription: {
    textAlign: 'left',
    fontSize: theme.typography.pxToRem(16),
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: theme.typography.pxToRem(14),
      textAlign: 'left',
    },
  },
  partnerContent: {
    padding: '2em',
    [theme.breakpoints.between('md', 'md')]: {
      padding: '1.5em',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      padding: '.8em',
    },
  },
  partnerEachDiv: {
    padding: '.8em',
    [theme.breakpoints.between('md', 'md')]: {
      padding: '.5em',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      padding: '.3em',
    },
  },
  partnerBtnDiv: {
    textAlign: 'center',
    [theme.breakpoints.between('xs', 'md')]: {
      fontSize: theme.typography.pxToRem(10),
    },
  },
  btnDiv: {
    textTransform: 'inherit',
  },
}))

interface PartnerProps {
  heading: string
  description: string
}

const Partner: React.FC<PartnerProps> = (props) => {
  const { heading, description } = props
  const classes = useStyles()
  return (
    <>
      <Grid className={classes.partnerHeading}>{heading}</Grid>
      <Grid className={classes.partnerDescription}>{description}</Grid>
    </>
  )
}

const AgencyPartner = () => {
  const intl = useIntl()
  const classes = useStyles()
  const heading = intl.formatMessage({ id: 'link.partners' })
  return (
    <Grid container justifyContent="center" className={classes.partner}>
      <Grid item justifyContent="center" sm={8}>
        <Grid className={classes.heading}>
          {heading}
        </Grid>
        <Grid container className={classes.partnerContent}>
          <Grid item md={6} className={classes.partnerEachDiv}>
            <Partner
              heading={intl.formatMessage({ id: 'agencyPage.partner.para1.title' })}
              description={intl.formatMessage({ id: 'agencyPage.partner.para1.description' })}
            />
          </Grid>
          <Grid item md={6} className={classes.partnerEachDiv}>
            <Partner
              heading={intl.formatMessage({ id: 'agencyPage.partner.para2.title' })}
              description={intl.formatMessage({ id: 'agencyPage.partner.para2.description' })}
            />
          </Grid>
          <Grid item md={6} className={classes.partnerEachDiv}>
            <Partner
              heading={intl.formatMessage({ id: 'agencyPage.partner.para3.title' })}
              description={intl.formatMessage({ id: 'agencyPage.partner.para3.description' })}
            />
          </Grid>
          <Grid item md={6} className={classes.partnerEachDiv}>
            <Partner
              heading={intl.formatMessage({ id: 'agencyPage.partner.para4.title' })}
              description={intl.formatMessage({ id: 'agencyPage.partner.para4.description' })}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.partnerBtnDiv}>
          <ButtonFormPopper displayedText={intl.formatMessage({ id: 'agencyPage.partner.btnText' })} />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default AgencyPartner
