import React from 'react'

import { useIntl } from 'gatsby-plugin-intl'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

const useStyles = makeStyles((theme) => ({
  links: {
    fontFamily: `'Gotham Bold', serif`,
    fontSize: theme.typography.pxToRem(15),
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    margin: '25px 0',
  },
  linksText: {
    fontSize: theme.typography.pxToRem(15),
    textTransform: 'uppercase',
    margin: '0 0.8rem',
    color: 'black',
    fontWeight: 600,
    textDecoration: 'unset',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  linksContainer: {
    justifyContent: 'center',
    [theme.breakpoints.between('xs', 'sm')]: {
    },
  },
  linksDiv: {
    [theme.breakpoints.between('xs', 'sm')]: {
    },
  },
}))

const AgencyLinks = () => {
  const intl = useIntl()
  const classes = useStyles()

  return (
    <Grid container className={classes.linksContainer}>
      <Grid container className={classes.linksDiv}>
        <Grid container item xs={12} justifyContent="space-between" className={classes.links}>
          <AnchorLink
            to={`/${intl.locale}/agency#team`}
            title={intl.formatMessage({ id: 'link.team' })}
            className={classes.linksText}
          >
            {intl.formatMessage({ id: 'link.team' })}
          </AnchorLink>
          <AnchorLink
            to={`/${intl.locale}/agency#experties`}
            title={intl.formatMessage({ id: 'link.experties' })}
            className={classes.linksText}
          >
            {intl.formatMessage({ id: 'link.experties' })}
          </AnchorLink>
          <AnchorLink
            to={`/${intl.locale}/agency#netflix`}
            title={intl.formatMessage({ id: 'link.netflix' })}
            className={classes.linksText}
          >
            {intl.formatMessage({ id: 'link.netflix' })}
          </AnchorLink>
          <AnchorLink
            to={`/${intl.locale}/agency#tech`}
            title={intl.formatMessage({ id: 'link.tech' })}
            className={classes.linksText}
          >
            {intl.formatMessage({ id: 'link.tech' })}
          </AnchorLink>
          <AnchorLink
            to={`/${intl.locale}/agency#network`}
            title={intl.formatMessage({ id: 'link.network' })}
            className={classes.linksText}
          >
            {intl.formatMessage({ id: 'link.network' })}
          </AnchorLink>
          <AnchorLink
            to={`/${intl.locale}/agency#partners`}
            title={intl.formatMessage({ id: 'link.partners' })}
            className={classes.linksText}
          >
            {intl.formatMessage({ id: 'link.partners' })}
          </AnchorLink>
          <AnchorLink
            to={`/${intl.locale}/agency#friend`}
            title={intl.formatMessage({ id: 'link.friend' })}
            className={classes.linksText}
          >
            {intl.formatMessage({ id: 'link.friend' })}
          </AnchorLink>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default AgencyLinks
