import React from 'react'

export default function useWindowSize(onResize: () => void) {
  const isSSR = typeof window === 'undefined'
  const [windowSize, setWindowSize] = React.useState({
    width: isSSR ? 1200 : window.innerWidth,
    height: isSSR ? 800 : window.innerHeight,
  })

  const changeWindowSize = React.useCallback(() => {
    onResize && onResize()
    setWindowSize({ width: window.innerWidth, height: window.innerHeight })
  }, [onResize])

  React.useEffect(() => {
    window.addEventListener('resize', changeWindowSize)

    return () => {
      window.removeEventListener('resize', changeWindowSize)
    }
  }, [changeWindowSize])

  return windowSize
}
