import React from 'react'

import { useIntl } from 'gatsby-plugin-intl'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  review: {
    minHeight: 'fit-content',
    backgroundColor: '#FAE9E5',
    justifyContent: 'center',
    [theme.breakpoints.between('xs', 'sm')]: {
    },
  },
  heading: {
    fontFamily: `'Cormorant Garamond', serif`,
    fontSize: theme.typography.pxToRem(130),
    textAlign: 'center',
    textTransform: 'uppercase',
    [theme.breakpoints.between('md', 'md')]: {
      fontSize: theme.typography.pxToRem(80),
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: theme.typography.pxToRem(40),
    },
  },
  reviewTitle: {
    fontFamily: `'Cormorant Garamond', serif`,
    fontSize: theme.typography.pxToRem(34),
    marginBottom: '3rem',
    [theme.breakpoints.between('md', 'md')]: {
      fontSize: theme.typography.pxToRem(25),
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: theme.typography.pxToRem(20),
    },
  },
  reviewContent: {
    flexBasis: '50%',
    [theme.breakpoints.between('xs', 'sm')]: {
      flexBasis: '80%',
    },
  },
  networkDiv: {
    fontFamily: `'Gotham Book', serif`,
    fontSize: theme.typography.pxToRem(16),
    paddingRight: theme.typography.pxToRem(40),
    [theme.breakpoints.between('md', 'md')]: {
      fontSize: theme.typography.pxToRem(14),
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: theme.typography.pxToRem(14),
    },
  },
  ff: {
    fontFamily: `'Gotham Book', serif`,
    textAlign: 'left',
    margin: '.5rem 0 ',
    [theme.breakpoints.between('md', 'md')]: {
      margin: '.3rem 0 ',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      margin: '.2rem 0 ',
    },
  },
  details: {
    margin: '2rem 0 ',
    [theme.breakpoints.between('md', 'md')]: {
      margin: '1.5rem 0 ',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      margin: '.8rem 0 ',
    },
  },
}))

const Review = () => {
  const intl = useIntl()
  const classes = useStyles()

  return (
    <Grid container className={classes.review}>
      <Grid className={classes.heading} xs={12}>
        {intl.formatMessage({ id: 'link.network' })}
      </Grid>
      <Grid item xs={12} className={classes.reviewContent}>
        <Grid item xs={12} className={classes.reviewTitle}>
          {intl.formatMessage({ id: 'agencyPage.network.title' })}
        </Grid>
        <Grid container xs={12} className={classes.details}>
          {intl
            .formatMessage({ id: 'network.detail' })
            .split('\n')
            .map((paragraph, indexParagraph) => {
              return (
                <Grid
                  key={indexParagraph + '_KeyNetworkDetails'}
                  container
                  item
                  direction="column"
                  xs={12}
                  className={classes.networkDiv}
                >
                  {paragraph.split('\n').map((sentence, indexSentence) => (
                    <Typography key={indexParagraph + '_' + indexSentence + '_TypoStringNetworkDetails'} className={classes.ff}>
                      {sentence}
                    </Typography>
                  ))}
                </Grid>
              )
            })}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Review
