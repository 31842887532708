import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { Image } from './Images'

type styleType = {
  [key: string]: string
}

interface BannerProps {
src?: string
videoSrc?: { mp4: string; poster: string }
rootStyle?: styleType
innerStyle?: styleType
className?: string
}

const useStyles = (rootStyle: styleType, innerStyle: styleType) =>
  makeStyles((theme) => ({
    root: {
      width: '100%',
      minHeight: '700px',
      ...rootStyle,
      [theme.breakpoints.between('xs', 'xs')]: {
        width: 'auto',
        minHeight: 'auto',
        marginLeft: '-100px',
        height: '400px',
      },
      [theme.breakpoints.between('sm', 'sm')]: {
        minHeight: 'auto',
        height: '400px',
      },
      [theme.breakpoints.between('md', 'md')]: {
        minHeight: 'auto',
      },
    },
    main: {
      zIndex: 999,
      overflowX: 'visible',
    },
    inner: {
      ...innerStyle,
      width: '100%',
    },
  }))

export const Banner: React.FC<BannerProps> = (props) => {
  
  const { src, videoSrc, rootStyle, children, innerStyle, className = '' } = props
  const classes = useStyles(rootStyle || {}, innerStyle || {})()
  return (
    <Grid container justifyContent="center" className={classes.main}>
      <Grid item xs={12}>
        {!!src && (
          <Image
            src={src}
            layout="CONSTRAINED"
            className={`${classes.root} ${className}`}
            alt="Banner"
            imgStyle={{ objectPosition: '50% 0%' }}
          />
        )}
        {!!videoSrc && (
          <video 
            onMouseEnter={(e: any) => {
              e.preventDefault();
              if (e.target.currentTime == 0) e.target.play()
            }}
            onClick={(e: any) => {
              e.preventDefault();
              if (e.target.paused) {
                e.target.play()
              } else {
                e.target.pause()
              }
            }}
            onTouchStart={(e: any) => {
              e.preventDefault();
              if (e.target.paused) {
                e.target.play()
              } else {
                e.target.pause()
              }
            }}
            poster={videoSrc.poster} 
            autoPlay={false}
            loop 
            muted 
            playsInline 
            preload="none"
            style={{ width: '100%', objectFit: 'fill', cursor: 'pointer'  }}
          >
            <source src={videoSrc.mp4} type="video/mp4" />
          </video>
        )}
      </Grid>
      <Grid item className={classes.inner}>
        {children}
      </Grid>
    </Grid>
  )
}
