import React, { useCallback, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'

import { useIntl } from 'gatsby-plugin-intl'
import clsx from 'clsx'
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import PhoneIcon from '@material-ui/icons/Phone'
import CloseIcon from '@material-ui/icons/Close'
import { Checkbox, FormLabel } from '@material-ui/core'
import { submitForm } from '../../utils/submitForm'
import Link from '@material-ui/core/Link'
import styled from 'styled-components'
import theme from '../../Theme/theme'

const useStyles = (width: string, marginLeft: number) =>
  makeStyles((theme) => ({
    contactContainer: {
      backgroundColor: '#FFF',
      width: `${width}`,
      padding: theme.spacing(10),
      marginLeft: `-${marginLeft}px`,
      [theme.breakpoints.between('xs', 'sm')]: {
        padding: theme.spacing(3),
      },
    },
    heading: {
      textTransform: 'uppercase',
      fontSize: theme.typography.pxToRem(16),
      fontWeight: 600,
      [theme.breakpoints.between('xs', 'sm')]: {
        fontSize: theme.typography.pxToRem(15),
      },
    },
    subHeading: {
      textTransform: 'uppercase',
      fontSize: theme.typography.pxToRem(25),
      fontWeight: 600,
    },
    container: {},
    font: {
      fontSize: theme.typography.pxToRem(20),
      marginRight: theme.spacing(2),
      paddingTop: '5px',
      marginBottom: '-2px',
    },
    w100: {
      width: '100%',
    },
    w75: {
      width: '75%',
    },
    transparent: {
      backgroundColor: 'transparent',
      borderBottom: 'none',
      borderRight: 'none',
    },
    mb2: {
      marginBottom: theme.typography.pxToRem(15),
    },
    pb2: {
      paddingBottom: '10px',
    },
    mt2: {
      marginTop: '20px',
    },
    mt5: {
      marginTop: theme.spacing(4),
    },
    pr3: {
      paddingRight: theme.spacing(3),
      [theme.breakpoints.between('xs', 'sm')]: {
        paddingRight: 'unset',
      },
    },
    pl3: {
      paddingLeft: theme.spacing(3),
      [theme.breakpoints.between('xs', 'sm')]: {
        paddingLeft: theme.spacing(0),
      },
    },
    closeBtn: {
      position: 'absolute',
      right: theme.spacing(3),
      marginTop: theme.spacing(-10),
      opacity: 0.5,
      [theme.breakpoints.between('xs', 'sm')]: {
        marginTop: theme.spacing(-3),
      },
    },
    fs2: {
      fontSize: theme.typography.pxToRem(40),
      [theme.breakpoints.between('xs', 'sm')]: {
        fontSize: theme.typography.pxToRem(60),
      },
    },
    details: {
      fontSize: '1rem',
      lineHeight: '1.6',
      [theme.breakpoints.between('xs', 'sm')]: {
        marginBottom: theme.spacing(10),
      },
    },
    contactBy: {
      fontSize: theme.typography.pxToRem(14),
      fontFamily: 'Gotham Bold',
      textTransform: 'uppercase',
    },
    blackColor: {
      color: '#000',
    },
    consent: {
      fontSize: '.8em',
      paddingTop: theme.spacing(0),
      paddingBottom: theme.spacing(1),
    },
  }))

interface AgentContactViaEmailProps {
  details?: string
  width: string
  marginLeft?: number
  onClose: () => void
  emailTo: string
  isPhoneDisplayed: boolean
  phone?: string | null
}

const Square = styled.div`
  width: 12px;
  height: 12px;
  border: 1px solid #606060;
  margin: 0px;
  background: transparent;
`
const FullSquare = styled.div`
  width: 12px;
  height: 12px;
  border: 1px solid #606060;
  margin: 0px;
  background: #979797;
`
const SquareRed = styled.div`
  width: 12px;
  height: 12px;
  border: 1px solid #ff0000;
  margin: 0px;
  background: transparent;
`
const reEmail =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

const AgentContactViaEmail: React.FC<AgentContactViaEmailProps> = (props) => {
  const { details, phone, isPhoneDisplayed, width, marginLeft = 0, onClose, emailTo } = props
  const classes = useStyles(width, marginLeft)()
  const intl = useIntl()
  const [formValues, setFormValues] = useState({
    emailTo,
    email: '',
    name: '',
    message: '',
    form: 'FCO',
    consent: false,
  } as any)
  const [error, setError] = useState(['initial'])
  const [submited, setSubmited] = useState(false)

  const handleChange = useCallback(
    (
      event: React.ChangeEvent<{
        name?: string
        value: unknown
        textContent?: any
        checked?: boolean
      }>,
    ) => {
      if (event && event.target && (event.target.name || event.target.textContent)) {
        setError([])
        setFormValues({
          ...formValues,
          [event.target.name || ('adresse' as string)]:
            (event.target.name == 'consent' ? event.target.checked : event.target.value) ||
            event.target.textContent,
        })
      }
    },
    [formValues],
  )

  const onSubmit = async () => {
    const validateField = Object.keys(formValues).filter(
      (key: string) =>
        !formValues[key] || (key == 'email' && !String(formValues[key]).match(reEmail)),
    ) as any
    if (validateField.length) {
      setError(validateField)
    } else {
      const result = await submitForm(formValues)
      if (result) {
        setSubmited(true)
        setError([])
      }
    }
  }

  return (
    <Grid className={classes.contactContainer} container justifyContent="center">
      <Grid className={classes.container}>
        <IconButton aria-label="close" className={classes.closeBtn} onClick={onClose}>
          <CloseIcon className={classes.fs2} />
        </IconButton>
        <Grid item xs={12} container>
          <Grid
            xs={12}
            md={6}
            container
            className={clsx(classes.pr3, classes.details)}
            direction="column"
          >
            {isPhoneDisplayed && (
              <Grid
                item
                justifyContent="center"
                style={{ alignItems: 'center', paddingBottom: '2rem' }}
                direction="row"
              >
                <Grid item>
                  <PhoneIcon className={classes.font} />
                  <span className={classes.contactBy}>
                    {intl.formatMessage({ id: 'contactByPhone' })}
                  </span>
                </Grid>
                <Grid item>
                  <span className={classes.details}>{phone}</span>
                </Grid>
              </Grid>
            )}
            {details && details != '' && (
              <Grid item justifyContent="center" style={{ alignItems: 'center' }} direction="row">
                {details}
              </Grid>
            )}
          </Grid>
          <Grid xs={12} md={6} item container justifyContent="center" className={classes.pl3}>
            {submited ? (
              intl.formatMessage({ id: 'send_confirm' })
            ) : (
              <Grid container>
                <Grid xs={12} item>
                  <MailOutlineIcon className={classes.font} />
                  <span className={classes.contactBy}>
                    {intl.formatMessage({ id: 'contactByEmail' })}
                  </span>
                </Grid>
                <Grid xs={12} item className={clsx(classes.mt5)}>
                  <TextField
                    id="name"
                    name="name"
                    label={intl.formatMessage({ id: 'yourName' })}
                    InputProps={{
                      className: classes.pb2,
                    }}
                    InputLabelProps={{
                      className: classes.blackColor,
                    }}
                    error={error.includes('name')}
                    className={clsx(classes.w100, classes.mb2)}
                    onChange={handleChange}
                    value={formValues.name}
                  />
                </Grid>
                <Grid xs={12} item>
                  <TextField
                    id="email"
                    name="email"
                    label={intl.formatMessage({ id: 'yourEmail' })}
                    error={error.includes('email')}
                    onChange={handleChange}
                    className={clsx(classes.w100, classes.mb2)}
                    InputLabelProps={{
                      className: classes.blackColor,
                    }}
                    InputProps={{
                      className: classes.pb2,
                    }}
                  />
                </Grid>
                <Grid xs={12} item className={clsx(classes.mt5)}>
                  <FormLabel
                    id="message"
                    component="label"
                    error={error.includes('message')}
                    className={clsx(classes.w100, classes.mb2, classes.blackColor)}
                  >
                    {intl.formatMessage({ id: 'yourMessage' }) + '...'}
                  </FormLabel>
                </Grid>
                <Grid xs={12} style={{ position: 'relative' }} item>
                  <TextareaAutosize
                    onChange={handleChange}
                    name="message"
                    className={clsx(classes.w100, classes.transparent, classes.mb2, classes.mt2)}
                    aria-label={intl.formatMessage({ id: 'yourMessage' })}
                    minRows={8}
                  />
                </Grid>
                <Grid xs={12} item className={classes.consent} justifyContent="center">
                  <Grid style={{ textAlign: 'left', paddingTop: '8px', paddingBottom: '8px' }}>
                    <span>
                      {intl.formatMessage({ id: 'legalConsent.agentContactViaEmail.agree' })}
                      <Checkbox
                        size="small"
                        icon={
                          formValues.consent == false && !error.includes('initial') ? (
                            <SquareRed />
                          ) : (
                            <Square />
                          )
                        }
                        checkedIcon={<FullSquare />}
                        checked={formValues.consent}
                        onChange={handleChange}
                        name={'consent'}
                        style={{ padding: '0 0 0 4px' }}
                      />
                    </span>
                  </Grid>
                  <Grid style={{ textAlign: 'left', paddingTop: '12px', paddingBottom: '8px' }}>
                    <span>
                      {intl.formatMessage({
                        id: 'legalConsent.agentContactViaEmail.moreInformation',
                      })}
                      <Link href={'/' + intl.locale + '/legal-privacy-policy'} underline="none">
                        {intl.formatMessage({ id: 'clickHere' })}
                      </Link>
                    </span>
                  </Grid>
                </Grid>
                <Grid xs={12} item className={classes.mt5}>
                  <Button
                    variant="outlined"
                    style={{ textTransform: 'initial' }}
                    onClick={onSubmit}
                  >
                    {intl.formatMessage({ id: 'send' })}
                  </Button>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default AgentContactViaEmail
