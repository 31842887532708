import React from 'react'

import { useIntl } from 'gatsby-plugin-intl'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import { ImageText } from '../ImageText'

const useStyles = makeStyles((theme) => ({
  imageWithTextContainer: {
    fontFamily: `'Cormorant Garamond', serif`,
    justifyContent: 'center',
  },

  imageWithTextContainerDiv: {
    flexBasis: '80%',
    [theme.breakpoints.between('xs', 'sm')]: {
      marginTop: theme.typography.pxToRem(10),
      marginBottom: 'unset',
      flexBasis: '95%',
    },
    [theme.breakpoints.between('md', 'md')]: {
      marginTop: theme.typography.pxToRem(10),
      marginBottom: 'unset',
      flexBasis: '80%',
    },
  },
  technologyTitle: {
    fontFamily: `'Cormorant Garamond', serif`,
    fontSize: theme.typography.pxToRem(130),
    textAlign: 'center',
    textTransform: 'uppercase',
    lineHeight: '.9',
    [theme.breakpoints.between('md', 'md')]: {
      fontSize: theme.typography.pxToRem(80),
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: theme.typography.pxToRem(40),
    },
  },
  technologyDetails: {
    fontFamily: `'Cormorant Garamond', serif`,
    fontSize: theme.typography.pxToRem(34),
    marginBottom: '3rem',
    marginTop: '3rem',
    [theme.breakpoints.between('md', 'md')]: {
      fontSize: theme.typography.pxToRem(25),
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: theme.typography.pxToRem(20),
    },
  },
  technologyDescDiv: {
    width: '100%',
    [theme.breakpoints.between('xs', 'sm')]: {
      width: '90%',
    },
    [theme.breakpoints.between('md', 'md')]: {
      width: '80%',
    },
    margin: 'auto',
  },
}))

const AgencyTechnology = () => {
  const intl = useIntl()
  const classes = useStyles()

  return (
    <Grid container className={classes.imageWithTextContainer}>
      <Grid item xs={12} className={classes.imageWithTextContainerDiv}>
        <Grid item sm={12} className={classes.technologyDescDiv}>
          <Grid item sm={12} className={classes.technologyTitle}>
            {intl.formatMessage({ id: 'innovation' })}
          </Grid>
          <Grid container justifyContent="center">
            <Grid item className={classes.technologyDetails}>
              {intl
                .formatMessage({ id: 'agencyPage.technology.description' })
                .split('\n')
                .map((s, index) => (
                  <div key={index}>{s}</div>
                ))}
            </Grid>
          </Grid>
        </Grid>
        <ImageText
          imagePosition="right"
          src="a5.png"
          alt={intl.formatMessage({ id: 'agencyPage.imageTextPanel1.heading' })}
          heading={intl.formatMessage({ id: 'agencyPage.imageTextPanel1.heading' })}
          mainText={intl.formatMessage({ id: 'agencyPage.imageTextPanel1.description' })}
          imagesubText={intl.formatMessage({ id: 'agencyPage.imageTextPanel1.subDescription' })}
        />
      </Grid>
      <Grid item xs={12} className={classes.imageWithTextContainerDiv}>
        <ImageText
          imagePosition="left"
          flixelId="jtsueywkv381kalwb9l5"
          alt={intl.formatMessage({ id: 'agencyPage.imageTextPanel2.heading' })}
          heading={intl.formatMessage({ id: 'agencyPage.imageTextPanel2.heading' })}
          mainText={intl.formatMessage({ id: 'agencyPage.imageTextPanel2.description' })}
          imagesubText={intl.formatMessage({ id: 'agencyPage.imageTextPanel2.subDescription' })}
        />
      </Grid>
      <Grid item xs={12} className={classes.imageWithTextContainerDiv}>
        <ImageText
          imagePosition="right"
          src="a7.png"
          alt={intl.formatMessage({ id: 'agencyPage.imageTextPanel3.heading' })}
          heading={intl.formatMessage({ id: 'agencyPage.imageTextPanel3.heading' })}
          mainText={intl.formatMessage({ id: 'agencyPage.imageTextPanel3.description' })}
          imagesubText={intl.formatMessage({ id: 'agencyPage.imageTextPanel3.subDescription' })}
        />
      </Grid>
    </Grid>
  )
}

export default AgencyTechnology
