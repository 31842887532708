import React, { useEffect, useRef } from 'react'
import { graphql, PageProps, useStaticQuery } from 'gatsby'
import { useIntl } from 'gatsby-plugin-intl'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'

import { Banner } from '../component/Banner'
import { Image } from '../component/Images'
import { Layout } from '../component/Layout'
import { AgencyDetails } from '../component/page_component/AgencyDetails'
import AgencyGallery from '../component/page_component/AgencyGallery'
import AgencyLinks from '../component/page_component/AgencyLinks'
import AgencyTechnology from '../component/page_component/AgencyTechnology'
import AgencyPartner from '../component/page_component/AgencyPartner'
import Experts from '../component/page_component/Experts'
import FriendsOfHouse from '../component/page_component/FriendsOfHouse'
import Intro from '../component/page_component/Intro'
import Review from '../component/page_component/Review'
import Team from '../component/page_component/Team'
import hreflangConfig from '../utils/hreflangConfig'
import { Helmet } from 'react-helmet'

const useStyles = makeStyles((theme) => ({
  introText: {
    fontFamily: `'Cormorant Garamond', serif`,
    fontSize: theme.typography.pxToRem(50),
    lineHeight: 1,
    fontWeight: 400,
    width: '100%',
    margin: '.8em 0',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    [theme.breakpoints.between('md', 'md')]: {
      fontSize: theme.typography.pxToRem(30),
      margin: '.5em 0',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: theme.typography.pxToRem(25),
      margin: '.3em 0',
    },
  },
  introContainer: {
    textAlign: 'center',
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(15),
    [theme.breakpoints.between('md', 'md')]: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(6),
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(4),
    },
  },
  teamContainer: {
    backgroundColor: '#FAE9E5',
    textAlign: 'center',
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(15),
    [theme.breakpoints.between('md', 'md')]: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(6),
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(4),
    },
  },
  friendOfTheHouseContainer: {
    backgroundColor: '#FAE9E5',
    textAlign: 'center',
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(15),
    [theme.breakpoints.between('md', 'md')]: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(6),
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(4),
    },
  },
  expertsContainer: {
    textAlign: 'center',
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(15),
    [theme.breakpoints.between('md', 'md')]: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(6),
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(4),
    },
  },
  partnersContainer: {
    textAlign: 'center',
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(15),
    [theme.breakpoints.between('md', 'md')]: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(6),
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(4),
    },
  },
  techContainer: {
    textAlign: 'center',
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(15),
    [theme.breakpoints.between('md', 'md')]: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(6),
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(4),
    },
  },
  pictureInsideContainer: {
    textAlign: 'center',
    margin: '2em 0',
    [theme.breakpoints.between('md', 'md')]: {
      margin: '1.5em 0',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      margin: '.8em 0',
    },
  },
  introImageFooter: {
    margin: '.8em 0',
    [theme.breakpoints.between('md', 'md')]: {
      margin: '.5em 0',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      margin: '.3em 0',
    },
  },
  agencyContainer: {
    backgroundColor: '#FAE9E5',
    textAlign: 'center',
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(15),
    [theme.breakpoints.between('md', 'md')]: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(6),
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(4),
    },
  },
  networkContainer: {
    backgroundColor: '#FAE9E5',
    textAlign: 'center',
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(15),
    [theme.breakpoints.between('md', 'md')]: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(6),
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(4),
    },
  },
  introDiv: {
    [theme.breakpoints.between('xs', 'sm')]: {},
  },
  img: {
    paddingRight: '1rem',
  },
  imagNew: {},
  gallery: {
    minHeight: '40vh',
    backgroundColor: '#FAE9E5',
  },
  fullWidth: {
    width: '100%',
  },
  description: {
    fontSize: theme.typography.pxToRem(18),
    fontFamily: `'Cormorant Garamond', serif`,
    lineHeight: 1,
    [theme.breakpoints.between('md', 'md')]: {
      fontSize: theme.typography.pxToRem(14),
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: theme.typography.pxToRem(15),
    },
  },
  profile: {
    textAlign: 'center',
    flexBasis: '40%',
    [theme.breakpoints.between('xs', 'sm')]: {
      flexBasis: '60%',
    },
  },
  intro: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.between('xs', 'sm')]: {},
  },
  imageWrapper: {
    position: 'absolute',
    right: '20%',
    [theme.breakpoints.between('md', 'md')]: {},
    [theme.breakpoints.between('xs', 'sm')]: {
      right: '15%',
    },
  },
  icon: {
    width: '15rem',
    height: 'auto',
    [theme.breakpoints.between('md', 'md')]: {
      width: '12rem',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      width: '8rem',
    },
  },
  sliderDiv: {
    [theme.breakpoints.between('xs', 'sm')]: {},
  },
}))

const Agency: React.FC<PageProps> = (props) => {
  const { location } = props
  const intl = useIntl()
  const classes = useStyles()
  const teamRef = useRef<null | HTMLElement>(null)
  const friendRef = useRef<null | HTMLElement>(null)

  useEffect(() => {
    if (window.location.hash) {
      const id = window.location.hash.split('#')[1]
      if (id === 'team' && teamRef && teamRef.current) {
        teamRef.current.scrollIntoView()
      }
      if (id === 'friend' && friendRef && friendRef.current) {
        friendRef.current.scrollIntoView()
      }
    }
  }, [])

  const hreflangLinks = hreflangConfig['/fr/agency/']
  const dataCanonical = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `)
  const canonicalUrl = `${dataCanonical.site.siteMetadata.siteUrl}${location.pathname}`
  return (
    <Layout location={location} title={intl.formatMessage({ id: "L'Agence" })}>
      <Helmet>
        <link rel="canonical" href={canonicalUrl} />
        {hreflangLinks &&
          Object.keys(hreflangLinks).map((lang) => (
            <link key={lang} rel="alternate" hrefLang={lang} href={hreflangLinks[lang]} />
          ))}
        <meta name="description" content="L'agence - Kretz" />
      </Helmet>
      <Banner
        src="a9.png"
        innerStyle={{
          zIndex: '1100',
        }}
        className={classes.imagNew}
      />
      <AgencyLinks />
      <Grid container justifyContent="center" className={classes.introDiv}>
        <Grid container className={classes.introContainer}>
          <Grid item md={2} />
          <Grid item md={8}>
            <Intro
              title={intl.formatMessage({ id: 'mainScreen.second.title' })}
              description={intl.formatMessage({ id: 'agencyPage.detail' })}
              showCircle
              showBtn={false}
            />
          </Grid>
          <Grid item md={2} />

          <Grid item md={1} />
          <Grid md={10} container className={classes.pictureInsideContainer}>
            <Image src="a10.png" alt="L'Agence Kretz à Boulogne" layout="CONSTRAINED" />
          </Grid>
          <Grid item md={1} />

          <Grid item md={3} />
          <Grid item md={6}>
            <div className={classes.introText}>
              " {intl.formatMessage({ id: 'agencyPage.intro' })} "
            </div>
          </Grid>
          <Grid item md={3} />

          <Grid item md={1} />
          <Grid item xs={10} className={classes.introImageFooter}>
            <Image src="a8.png" alt="L'Agence Kretz - Famille" layout="FULL_WIDTH" />
          </Grid>
          <Grid item md={1} />
        </Grid>
      </Grid>
      <Grid container className={classes.teamContainer}>
        <Box id="team" className={classes.fullWidth} ref={teamRef}>
          <Team />
        </Box>
      </Grid>
      <Grid container className={classes.expertsContainer}>
        <Box id="experties">
          <Experts />
        </Box>
      </Grid>
      <Grid container className={classes.agencyContainer}>
        <Box id="netflix">
          <AgencyDetails showSildeShow={false} showSubtitle={false} showEpisode />
        </Box>
      </Grid>
      <Grid container className={classes.techContainer}>
        <Box id="tech">
          <AgencyTechnology />
        </Box>
      </Grid>
      <Grid container className={classes.networkContainer}>
        <Box id="network">
          <Review />
        </Box>
      </Grid>
      <Grid container className={classes.partnersContainer}>
        <Box id="partners">
          <AgencyPartner />
        </Box>
      </Grid>
      <Grid container className={classes.friendOfTheHouseContainer}>
        <Box id={'friend'}>
          <FriendsOfHouse isTitleHanging={true} />
        </Box>
        <AgencyGallery />
      </Grid>
    </Layout>
  )
}

export default Agency
