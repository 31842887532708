import React from 'react'

import { useIntl } from 'gatsby-plugin-intl'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  experties: {
    justifyContent: 'center',
    [theme.breakpoints.between('xs', 'sm')]: {
    },
  },
  partner: {
    minHeight: '100vh',
    justifyContent: 'center',
  },
  expertTitle: {
    fontFamily: `'Cormorant Garamond', serif`,
    fontSize: theme.typography.pxToRem(130),
    textAlign: 'center',
    textTransform: 'uppercase',
    [theme.breakpoints.between('md', 'md')]: {
      fontSize: theme.typography.pxToRem(80),
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: theme.typography.pxToRem(40),
    },
  },
  partnerContent: {
    flexBasis: '70%',
    margin: '2em 0',
    [theme.breakpoints.between('md', 'md')]: {
      margin: '1.5em 0',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      margin: '.8em 0',
    },
  },
  expertHeading: {
    fontFamily: `'Gotham Medium', serif`,
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(16),
    textAlign: 'left',
    margin: '1em 0',
    marginTop: '2.3em',
    [theme.breakpoints.between('md', 'md')]: {
      fontSize: theme.typography.pxToRem(12),
      margin: '.8em 0',
      marginTop: '1.5em',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: theme.typography.pxToRem(12),
      margin: '.5em 0',
      marginTop: '1em',
    },
  },
  expertSubtitle: {
    fontFamily: `'Gotham Medium', serif`,
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(16),
    textAlign: 'left',
    margin: '.8em 0',
    [theme.breakpoints.between('md', 'md')]: {
      fontSize: theme.typography.pxToRem(12),
      margin: '.5em 0',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: theme.typography.pxToRem(12),
      margin: '.3em 0',
    },
  },
  expertDescription: {
    fontFamily: `'Gotham Book', serif`,
    fontSize: theme.typography.pxToRem(16),
    textAlign: 'left',
    [theme.breakpoints.between('md', 'md')]: {
      fontSize: theme.typography.pxToRem(12),
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: theme.typography.pxToRem(12),
    },
  },
  expertEachDiv: {
    padding: '0 2.5em',
    [theme.breakpoints.between('md', 'md')]: {
      padding: '0 1.9em',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      padding: '0 1.1em',
    },
  },
  expertBtnDiv: {
    textAlign: 'center',
  },
}))

const Experts = () => {
  const intl = useIntl()
  const classes = useStyles()

  return (
    <Grid container className={classes.experties}>
      <Grid className={classes.expertTitle}>{intl.formatMessage({ id: 'link.experties' })}</Grid>
      <Grid container className={classes.partnerContent}>
        <Grid item md={6} className={classes.expertEachDiv}>
          <Grid className={classes.expertHeading}>
            {intl.formatMessage({ id: 'agencyPage.expert.para1.title' })}
          </Grid>
          <Grid className={classes.expertSubtitle}>
            {intl.formatMessage({ id: 'agencyPage.expert.para1.subtitle' })}
          </Grid>
          <Grid className={classes.expertDescription}>
            <div>• {intl.formatMessage({ id: 'agencyPage.expert.para1.text1' })}</div>
            <div>• {intl.formatMessage({ id: 'agencyPage.expert.para1.text2' })}</div>
            <div>• {intl.formatMessage({ id: 'agencyPage.expert.para1.text3' })}</div>
            <div>• {intl.formatMessage({ id: 'agencyPage.expert.para1.text4' })}</div>
            <div>• {intl.formatMessage({ id: 'agencyPage.expert.para1.text5' })}</div>
          </Grid>
        </Grid>
        <Grid item md={6} className={classes.expertEachDiv}>
          <Grid className={classes.expertHeading}>
            {intl.formatMessage({ id: 'agencyPage.expert.para2.title' })}
          </Grid>
          <Grid className={classes.expertSubtitle}>
            {intl.formatMessage({ id: 'agencyPage.expert.para2.subtitle' })}
          </Grid>
          <Grid className={classes.expertDescription}>
            <div>• {intl.formatMessage({ id: 'agencyPage.expert.para2.text1' })}</div>
            <div>• {intl.formatMessage({ id: 'agencyPage.expert.para2.text2' })}</div>
            <div>• {intl.formatMessage({ id: 'agencyPage.expert.para2.text3' })}</div>
            <div>• {intl.formatMessage({ id: 'agencyPage.expert.para2.text4' })}</div>
          </Grid>
        </Grid>
        <Grid item md={6} className={classes.expertEachDiv}>
          <Grid className={classes.expertHeading}>
            {intl.formatMessage({ id: 'agencyPage.expert.para3.title' })}
          </Grid>
          <Grid className={classes.expertDescription}>
            <div>• {intl.formatMessage({ id: 'agencyPage.expert.para3.text1' })}</div>
            <div>• {intl.formatMessage({ id: 'agencyPage.expert.para3.text2' })}</div>
            <div>• {intl.formatMessage({ id: 'agencyPage.expert.para3.text3' })}</div>
          </Grid>
        </Grid>
        <Grid item md={6} className={classes.expertEachDiv}>
          <Grid className={classes.expertHeading}>
            {intl.formatMessage({ id: 'agencyPage.expert.para4.title' })}
          </Grid>
          <Grid className={classes.expertSubtitle}>
            {intl.formatMessage({ id: 'agencyPage.expert.para4.subtitle' })}
          </Grid>
          <Grid className={classes.expertDescription}>
            <div>• {intl.formatMessage({ id: 'agencyPage.expert.para4.text1' })}</div>
            <div>• {intl.formatMessage({ id: 'agencyPage.expert.para4.text2' })}</div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Experts
