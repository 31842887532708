import React from 'react'
import Carousel from 'react-multi-carousel'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import { Image } from '../Images'

const useStyles = makeStyles((theme) => ({
  img: {
    paddingRight: '1rem',
  },
  gallery: {
    // minHeight: '40vh',
    backgroundColor: '#FAE9E5',
    [theme.breakpoints.between('xs', 'xs')]: {
      paddingBottom: theme.spacing(13),
      paddingTop: theme.spacing(2),
    },
  },
}))

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 3,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 2,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
}

const AgencyGallery = () => {
  const classes = useStyles()

  return (
    <Grid container className={classes.gallery}>
      <Grid item xs={12}>
        <Carousel
          responsive={responsive}
          additionalTransfrom={0}
          arrows
          autoPlaySpeed={5000}
          centerMode
          containerClass="container"
          focusOnSelect={false}
          infinite
          keyBoardControl
          minimumTouchDrag={80}
          renderButtonGroupOutside={false}
          renderDotsOutside={false}
        >
          <Box className={classes.img}>
            <Image src="a1.png" alt="Gallerie L'Agence Kretz - Image n°1" layout="CONSTRAINED" />
          </Box>
          <Box className={classes.img}>
            <Image src="a3.png" alt="Gallerie L'Agence Kretz - Image n°2" layout="CONSTRAINED" />
            <span>Cyril Hanouna</span>
          </Box>
          <Box className={classes.img}>
            <Image src="a2.png" alt="Gallerie L'Agence Kretz - Image n°3" layout="CONSTRAINED" />
            <span>Lucas Moura</span>
          </Box>
          <Box className={classes.img}>
            <Image src="a11.png" alt="Gallerie L'Agence Kretz - Image n°4" layout="CONSTRAINED" />
            <span>Laurent Deutch</span>
          </Box>
        </Carousel>
      </Grid>
    </Grid>
  )
}

export default AgencyGallery
