import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'
import { Image } from '../component/Images'
import styled from 'styled-components'
import { Cinemagraph } from './Cimenegraph'
import { getCookieValue } from '../utils/cookies'

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: `'Gotham Book', serif`,
    fontSize: theme.typography.pxToRem(17),
    fontWeight: 'bold',
    borderBottom: '1.5px solid #b1aaaa',
    textAlign: 'center',
    textTransform: 'uppercase',
    paddingBottom: theme.typography.pxToRem(20),
    marginBottom: theme.typography.pxToRem(20),
    [theme.breakpoints.between('xs', 'md')]: {
      textAlign: 'center',
      fontSize: theme.typography.pxToRem(16),
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      marginBottom: 'unset',
      fontSize: theme.typography.pxToRem(16),
    },
  },
  description: {
    fontFamily: `'Gotham Book', serif`,
    fontSize: theme.typography.pxToRem(16),
    textTransform: 'uppercase',
    marginBottom: theme.typography.pxToRem(20),
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: theme.typography.pxToRem(14),
    },
  },
  subDescription: {
    fontFamily: `'Gotham Book', serif`,
    fontSize: theme.typography.pxToRem(16),
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: theme.typography.pxToRem(14),
    },
  },
  textContainer: {
    padding: '2rem 8rem',
    [theme.breakpoints.between('xs', 'md')]: {
      padding: '2rem',
    },
  },
}))

interface ImageTextProps {
  imagePosition: 'left' | 'right'
  src?: string
  flixelId?: string
  alt: string
  heading: string
  mainText: string
  imagesubText: string
}

const ImageContainerRight = styled.div`
  margin-top: 5rem;
  margin-left: 4rem;
  @media (max-width: 1279.95px) and (min-width: 960px) {
    margin-right: 0;
    margin-left: 0;
  }
`

const ImageContainerLeft = styled.div`
  margin-top: 5rem;
  margin-right: 4rem;
  text-align: center;
  .gatsby-image-wrapper {
    width: 100%;
  }
  @media (max-width: 1279.95px) and (min-width: 960px) {
    margin-top: 2rem;
    margin-bottom: 2rem;
    margin-right: 0;
    margin-left: 0;
    .gatsby-image-wrapper {
      width: 100%;
    }
  }
  @media (max-width: 959.95px) and (min-width: 0px) {
    margin-top: 2rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
    margin-right: 0;
    margin-left: 0;
    .gatsby-image-wrapper {
      width: 100%;
    }
  }
`

export const ImageText: React.FC<ImageTextProps> = (props) => {
  const { imagePosition, src, flixelId, alt, heading, imagesubText, mainText } = props
  const classes = useStyles()

  const cookieName = 'kretz-cookie-consent'
  const [cookieValue, setCookieValue] = useState({});

  useEffect(() => {
    setCookieValue(getCookieValue(cookieName))
  }, [])

  return (
    <Grid container>
      <Hidden mdDown>
        {imagePosition === 'left' && (
          <Grid item lg={6} xs={12}>
            <ImageContainerRight>
              {flixelId ? (
                <Cinemagraph 
                  flixelId={flixelId}
                  flixelMobileId={flixelId}
                  thirdPartyCookie={cookieValue} 
                />
              ) : (
                <Image src={src ? src : ''} alt={alt} layout="CONSTRAINED" />
              )}
            </ImageContainerRight>
          </Grid>
        )}
      </Hidden>
      <Grid item lg={6} xs={12} className={classes.textContainer}>
        <Grid item xs={12} className={classes.title}>
          {heading}
        </Grid>
        <Hidden lgUp>
          <Grid item xs={12}>
            <ImageContainerLeft>
              {flixelId ? (
                <Cinemagraph 
                  flixelId={flixelId}
                  flixelMobileId={flixelId}
                  thirdPartyCookie={cookieValue} 
                />
              ) : (
                <Image src={src ? src : ''} alt={alt} layout="FULL_WIDTH" />
              )}
            </ImageContainerLeft>
          </Grid>
        </Hidden>
        <Grid item xs={12} className={classes.description}>
          {mainText}
        </Grid>
        <Grid item xs={12} className={classes.subDescription}>
          {imagesubText.split('\n').map((s, index) => (
            <div key={index}>{s}</div>
          ))}
        </Grid>
      </Grid>
      <Hidden mdDown>
        {imagePosition === 'right' && (
          <Grid item lg={6} xs={12}>
            <ImageContainerLeft>
              <Image src={src ? src : ''} alt={alt} layout="CONSTRAINED" />
            </ImageContainerLeft>
          </Grid>
        )}
      </Hidden>
    </Grid>
  )
}
